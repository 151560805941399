import React, { Fragment } from "react";
import Header from "../components/Header";
import RamanagaraPageDetails from "../templates/RamanagaraPageDetails";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from "../components/SEO";

const RamanagaraPage = () => {
  return (
    <Fragment>
      <SEO title="Ramanagara Services" />
      <Header />
      <RamanagaraPageDetails />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default RamanagaraPage;
