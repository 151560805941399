import React from "react";

const ServiceContentWrap = ({ service }) => {
  return (
    <div className="col-lg-8">
      <div className="service-details-content">
        <div className="service-details-info">
          <h3>{service.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: service.aboutServiceDesc }} />
        </div>
      </div>
    </div>
  );
};

export default ServiceContentWrap;
