import React from "react";
import ServicesData from "../../data/Services/allservices";

const ServiceContentWrap = ({ service }) => {
  let ServicesList = ServicesData;

  //  FILTER & OVERIDE
  if (service.urlIndex == "mysore") {
    ServicesList = ServicesList.filter((s) => {
      let { mysoreLink = "" } = s;
      if (mysoreLink && mysoreLink !== "") {
        s.link = mysoreLink;
        return s;
      }
    });
  } else if (service.urlIndex === "kangaroo-care-ramanagara") {
    ServicesList = ServicesList.filter((s) => {
      let { ramanagaraLink = "" } = s;
      if (ramanagaraLink && ramanagaraLink !== "") {
        s.link = ramanagaraLink;
        return s;
      }
    });
  }
  console.log(ServicesList);
  return (
    <div className="col-lg-8">
      <div className="service-details-content">
        <div className="service-thumbnail-wrapper">
          <img
            src={require("../../assets/img/" + service.previewImg)}
            alt={service.title}
            className="service-img"
          />
        </div>

        <div className="service-details-info">
          <h1>{service.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: service.aboutServiceDesc }} />
        </div>
        <br />
        <br />

        {service.urlIndex === "kangaroo-care-ramanagara" && (
          <>
            <div className="service-list">
              <h3>Services Offered</h3>
              <div className="row serviceRow">
                {ServicesList.map((service) => (
                  <div
                    key={service.id}
                    className="col-lg-6 col-md-6 serviceTitleDiv list-color-adder"
                  >
                    <a
                      target="_blank"
                      title={service.ramanagaraTitle}
                      href={service.link}
                    >
                      <h6>{service.ramanagaraTitle}</h6>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        {service.urlIndex == "mysore" && (
          <>
            <div className="service-list">
              <h3>Services Offered</h3>
              <div className="row serviceRow ">
                {ServicesList.map((service) => (
                  <div
                    key={service.id}
                    className="col-lg-6 col-md-6 serviceTitleDiv list-color-adder"
                  >
                    <a
                      target="_blank"
                      title={service.mysoreTitle}
                      href={service.link}
                    >
                      <h6>{service.mysoreTitle}</h6>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {service.urlIndex == "bangalore" && (
          <>
            <div className="service-list">
              <h3>Services Offered</h3>
              <div className="row serviceRow ">
                {ServicesList.map((service) => (
                  <div
                    key={service.id}
                    className="col-lg-6 col-md-6 serviceTitleDiv list-color-adder"
                  >
                    <a
                      target="_blank"
                      title={service.title}
                      href={service.link}
                    >
                      <h6>{service.title}</h6>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ServiceContentWrap;
