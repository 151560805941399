import React, { Fragment } from "react";
import PageHeader from "../../components/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import ServiceContentWrap from "./ServiceContentWrap";
import RelatedServices from "../../components/RelatedServices";
import Sidebar from "../../components/Sidebar";
import SidebarItem from "../../components/Sidebar/SidebarItem";
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";
import { Link } from "react-router-dom";
import Anchor from "../../components/UI/Anchor";

import FrequentlyAskedQuestions from "../../components/Faqs";
import NICUServiceFAQ from "../../helpers/SEO/NICUServiceFAQ";

import ServicesData from "../../data/RamanagaraPage/allservicesRamanagara.json";

//  HELPERS
import { redirectTo404 } from "../../helpers";
import RedirectWithStatus from "../../helpers/RedirectWithStatus";

import SEO from "../../components/SEO";

const ServiceDetails = () => {
  //  FIND PATH NAME
  let { pathname } = window.location;
  let pathnameArr = pathname.split("/");

  // console.log("pathnameArr", pathnameArr);

  //  IF KEY NOT FOUND
  if (pathnameArr[1] === undefined) {
    redirectTo404();
  }

  const servicePath = pathnameArr[1];
  // console.log("servicePath", servicePath);
  const service = ServicesData.find(
    (serviceItem) => serviceItem.link == servicePath
  );

  // console.log("service", service);

  let { hasBlog = false, hasFaq = false, id: serviceID } = service;

  return (
    <Fragment>
      <SEO />
      <PageHeader
        bgImg={require("../../assets/img/service/service-bg-1.jpg")}
        title={service.bgTitle ? service.bgTitle : service.title}
      />

      <PageWrapper classes="sm-top service-details-wrapper bs100">
        <ServiceContentWrap service={service} />
        <Sidebar classes={"col-lg-4 mtn-40"}>
          {/* 
          <SidebarItem title="Services">
            <List classes="service-list">
              {ServicesData.map((serviceItem) => (
                <LI key={serviceItem.id}>
                  <Link to={serviceItem.link}>{serviceItem.title}</Link>
                </LI>
              ))}
            </List>
          </SidebarItem>
          */}

          <SidebarItem title="Contact Pregnancy Care">
            <List classes="service-list">
              <LI>
                <div className="container" style={{ textAlign: "center" }}>
                  <a href="/book-appointment" style={{ margin: "0 auto" }}>
                    <button className="book-appointment">
                      Book Appointment
                    </button>
                  </a>
                </div>
              </LI>
              <LI>
                <Anchor path="/contact">
                  <i className="fa fa-map-marker contact-icon" />
                  #89, 17th cross, MC layout, Near Balagangadharnath Swamy Metro
                  Station, Vijayanagar, Bangalore 560040.
                </Anchor>
              </LI>
              <LI>
                <Anchor path="/contact">
                  <i className="fa fa-phone-square contact-icon" />
                  Toll Free - 1800-425-4500
                </Anchor>
              </LI>
              <LI>
                <Anchor path="/contact">
                  <i className="fa fa-envelope contact-icon" />
                  info@kangaroocareindia.com
                </Anchor>
              </LI>
            </List>
          </SidebarItem>
        </Sidebar>
      </PageWrapper>
      {/* <RelatedServices /> */}
    </Fragment>
  );
};

export default ServiceDetails;
